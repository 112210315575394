import React from 'react';
import styles from './email-subscribe.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Arrow from '../arrow/arrow';

interface Props {
  className?: string;
  buttonTextOverride?: string;
}

const EmailSubscribe: React.FC<Props> = props => {
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    setError('');
    const response = await fetch(`${process.env.REACT_APP_API}/v1/mailchimp-subscribe`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    const result = await response.json();
    if (result.success) {
      setIsSuccess(true);
    } else {
      setError('An unexpected error has occurred. Please try again.');
    }
  };
  
  return (
    <div className={`${styles.emailWrap} ${props.className || ''}`}>
      <TextField
        label="Email address"
        variant="outlined"
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        fullWidth
        error={!!error}
        helperText={isSuccess ? 'Successfully subscribed.' : error}
      />
      <Button
        className={styles.button}
        variant="contained"
        color="secondary"
        startIcon={<Arrow color={isSuccess ? 'disabled' : 'primary'} />}
        onClick={handleSubmit}
        disabled={isSuccess}
      >
        {props.buttonTextOverride || 'Submit'}
      </Button>
    </div>
  );
};

export default EmailSubscribe;
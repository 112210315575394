import {default as SvgIcon, SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

const Arrow: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} style={{marginRight: '-0.4em', ...props.style}}>
      <g transform="translate(0.000000,24.000000) scale(0.001662,-0.001497)" stroke="none">
        <path d="M2 16013 c3 -10 747 -1813 1653 -4007 1437 -3480 1647 -3993 1638
        -4015 -5 -14 -747 -1810 -1648 -3991 -901 -2181 -1640 -3973 -1643 -3982 -4
        -17 80 -18 1655 -18 l1660 0 31 73 c355 851 3272 7930 3272 7942 0 12 -2908
        7069 -3272 7943 l-31 72 -1660 0 c-1575 0 -1659 -1 -1655 -17z"/>
      </g>
    </SvgIcon>
  );
};

export default Arrow;
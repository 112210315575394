import React from 'react';
import Hero from '../../components/hero/hero';
import styles from './solutions.module.css';
import bgImage from '../../assets/solutions.jpg';
import { getSolutionsPageData } from '../../queries';
import { useQuery } from '@apollo/client';
import IconProvider from '../../services/icon-provider';
import SolutionItem from '../../components/solution-item/solution-item';

const Solutions: React.FC = () => {
  const {data} = useQuery(getSolutionsPageData);

  React.useEffect(() => {
    document.title = 'Heco et al - Solutions';
    document.querySelector('meta[name="description"]')?.setAttribute('content', data?.solutionsSection.data.attributes.Subtitle);
  }, [data]);

  return (
    <>
      <Hero className={styles.hero} img={bgImage} title={data?.solutionsSection.data.attributes.Title} text={data?.solutionsSection.data.attributes.Subtitle} />
      <div className={styles.delay}>
        {
          data ?
            <div className={styles.solutions}>
              <div>
                {
                  data.solutions.data.map((item: any, index: number) => {
                    const Icon = IconProvider.get(item.attributes.Icon.Name);
                    return (
                      <SolutionItem
                        key={index} 
                        title={item.attributes.Name}
                        icon={<Icon fontSize="inherit" color="primary" />}
                        description={item.attributes.ShortDescription}
                        link={item.attributes.Path}
                      />
                    );
                  })
                }
              </div>
            </div>
            : null
        }
      </div>
    </>
  );
};

export default Solutions;
import React from 'react';
import styles from './team-member-item.module.css';
import Arrow from '../arrow/arrow';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import LinkedIn from '../../components/linkedin/linkedin';
import sanitize from 'sanitize-html';
import showdown from 'showdown';

interface Props {
  name: string;
  title: string;
  phone?: string;
  email?: string;
  linkedIn?: string;
  details: string;
  imageUrl: string;
}

const MarkdownConverter = new showdown.Converter();

const TeamMemberItem: React.FC<Props> = props => {
  const details = MarkdownConverter.makeHtml(props.details);

  return (
    <div className={styles.personItem}>
      <div className={styles.image} style={{backgroundImage: `url(${props.imageUrl})`}} />
      <div className={styles.content}>
        <div className={styles.name}>
          <Arrow fontSize="inherit" color="primary" />
          <h3>{props.name}</h3>
        </div>
        <div className={styles.info}>
          <p>{props.title}</p>
        </div>
        {
          props.email ?
            <div className={styles.info}>
              <EmailOutlined className={styles.icon} fontSize="inherit" color="inherit" />
              <p>{props.email}</p>
            </div>
            : null
        }
        {
          props.phone ?
            <div className={styles.info}>
              <PhoneOutlined className={styles.icon} fontSize="inherit" color="inherit" />
              <p>{props.phone}</p>
            </div>
            : null
        }
        {
          props.linkedIn ?
            <div className={styles.info}>
              <LinkedIn className={styles.icon} fontSize="inherit" color="inherit" />
              <a href={props.linkedIn} target="_blank" rel="noreferrer">Connect on LinkedIn</a>
            </div>
            : null
        }
      </div>
      <div className={styles.details}>
        <p><strong>Key strengths</strong></p>
        <div dangerouslySetInnerHTML={{__html: sanitize(details)}} />
      </div>
    </div>
  );
};

export default TeamMemberItem;
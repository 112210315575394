import React from 'react';
import Hero from '../../components/hero/hero';
import styles from './insights.module.css';
import bgImage from '../../assets/insights.jpg';
import { useQuery } from '@apollo/client';
import { getInsights } from '../../queries';
import InsightsItem from '../../components/insights-item/insights-item';
import Arrow from '../../components/arrow/arrow';
import Loader from '../../components/loader/loader';

const pageSize = 10;

const Insights: React.FC = () => {
  const [page, setPage] = React.useState<number>(1);
  const [articles, setArticles] = React.useState<any[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const {data, loading} = useQuery(getInsights, {variables: {page, pageSize}});

  React.useEffect(() => {
    if (data && data.insights.data.length > 0) {
      setArticles(articles => [...articles, ...data.insights.data]);
    }
    if (data && data.insights.meta.pagination.pageCount === page) {
      setHasMore(false);
    }
  }, [data]);

  React.useEffect(() => {
    document.title = 'Heco et al - Insights';
    document.querySelector('meta[name="description"]')?.setAttribute('content', data?.insightsHeader.data.attributes.Subtitle);
  }, [data]);

  return (
    <>
      <Hero img={bgImage} title={data?.insightsHeader.data.attributes.Title} text={data?.insightsHeader.data.attributes.Subtitle} />

      <div className={styles.insights}>
        {
          articles.map((item: any, index: number) => (
            <InsightsItem
              key={index}
              title={item.attributes.Title}
              path={item.attributes.Path}
              summary={item.attributes.Summary}
              publishedAt={new Date(item.attributes.publishedAt)}
              img={`${process.env.REACT_APP_CMS_ENDPOINT}${item.attributes.Image.data.attributes.formats.thumbnail.url}`}
            />
          ))
        }
      </div>
      {
        hasMore ?
          <div className={styles.loadMore}>
            {
              loading ?
                <Loader color="primary" />
                :
                <div onClick={() => setPage(page => page + 1)}>
                  <p>Load more</p>
                  <Arrow className={styles.arrow} color="primary" fontSize="inherit" />
                </div>
            }
          </div>
          : null
      }
    </>
  );
};

export default Insights;
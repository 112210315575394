import React from 'react';
import Arrow from '../arrow/arrow';
import styles from './gallery.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  className?: string;
  images: {
    urlLarge: string;
    urlThumb: string;
    caption: string;
  }[];
}

const Gallery: React.FC<Props> = props => {
  const [largeImage, setLargeImage] = React.useState<{url: string, caption: string} | null>(null);
  const scrollElementRef = React.createRef<HTMLDivElement>();
  const scroll = (direction: 'forward' | 'back') => {
    if (scrollElementRef.current) {
      const elementWidth = scrollElementRef.current.offsetWidth;
      scrollElementRef.current.scrollBy({left: direction === 'forward' ? elementWidth*0.9 : -1*elementWidth*0.9, behavior:'smooth'});
    }
  };

  return (
    <>
      <div className={`${styles.gallery} ${props.className}`}>
        <Arrow className={styles.arrowPreviouse} color="inherit" fontSize="inherit" onClick={() => scroll('back')} />
        <div ref={scrollElementRef}>
          {
            props.images.map((item, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_CMS_ENDPOINT}${item.urlThumb}`}
                alt={item.caption}
                onClick={() => setLargeImage({url: item.urlLarge, caption: item.caption})}
              />
            ))
          }
        </div>
        <Arrow className={styles.arrowNext} color="inherit" fontSize="inherit" onClick={() => scroll('forward')} />
      </div>
      <div className={`${styles.fullSizeView} ${largeImage ? styles.active : ''}`} onClick={() => setLargeImage(null)}>
        <p>{largeImage?.caption}</p>
        {
          largeImage ?
            <div className={styles.imgContainer} style={{backgroundImage: `url(${`${process.env.REACT_APP_CMS_ENDPOINT}${largeImage.url}`})`}} />
            : null
        }
        <CloseIcon className={styles.closeIcon} color="inherit" fontSize="inherit" />
      </div>
    </>
  );
};

export default Gallery;
import React from 'react';
import Hero from '../../components/hero/hero';
import styles from './home.module.css';
import Arrow from '../../components/arrow/arrow';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getHomeData } from '../../queries';
import IconProvider from '../../services/icon-provider';
import SolutionItem from '../../components/solution-item/solution-item';
import {ReactComponent as ResearchIcon} from '../../assets/research.svg';
import {ReactComponent as CirclesIcon} from '../../assets/circles.svg';
import {ReactComponent as GearsIcon} from '../../assets/gears.svg';
import homeImg from '../../assets/home.jpg';
import trustTrackerImg from '../../assets/trust_tracker.jpg';
import {CSSTransition, SwitchTransition} from 'react-transition-group';

const Home: React.FC = () => {
  const {data} = useQuery(getHomeData);
  const location = useLocation();
  const solutionsRef = React.useRef<HTMLDivElement>(null);
  const [activeHero, setActiveHero] = React.useState<'science-based-marketing' | 'trust-tracker'>('science-based-marketing');

  React.useEffect(() => {
    if (solutionsRef.current && location.hash === '#solutions') {
      solutionsRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [location, !solutionsRef.current]);

  React.useEffect(() => {
    document.title = 'Heco et al';
    document.querySelector('meta[name="description"]')?.setAttribute('content', 'We support brand owners with actionable insights by utilizing state-of-the-art research methods and technology for consumer centric data');
    
    const interval = setInterval(() => {
      setActiveHero(state => state === 'trust-tracker' ? 'science-based-marketing' : 'trust-tracker');
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={activeHero}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="transition"
        >
          {
            activeHero === 'science-based-marketing' ?
              <Hero
                img={homeImg}
                title="SCIENCE-BASED MARKETING"
                text="We support brand owners with actionable insights by utilizing state-of-the-art research methods and technology for consumer centric data"
              />
              :
              <Hero className={styles.trustTrackerHero} img={trustTrackerImg} button={'READ MORE'} />
          }
        </CSSTransition>
      </SwitchTransition>
      <div className={styles.delayWrap}>
        {
          data ?
            <>
              <div className={styles.featured} ref={solutionsRef}>
                {
                  data ?
                    data.featuredQualities.data.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={styles.featuredWrap}>
                            <div key={index} className={styles.header}>
                              {index === 0 ? <ResearchIcon className={styles.icon} /> : index === 1 ? <CirclesIcon className={styles.icon} /> : <GearsIcon className={styles.icon} />}
                              <p>{item.attributes.Name}</p>
                            </div>
                            <div className={styles.description}>
                              <Arrow className={styles.arrow} color="primary" />
                              <p>{item.attributes.Description}</p>
                            </div>
                          </div>
                          {
                            index !== data.featuredQualities.data.length - 1 ?
                              <div className={styles.divider} />
                              : null
                          }
                        </React.Fragment>
                      );
                    })
                    : null
                }
              </div>
              <div className={styles.solutions}>
                <h2>{data.solutionsSection.data.attributes.Title}</h2>
                <p>{data.solutionsSection.data.attributes.Subtitle}</p>
                <div>
                  {
                    data.solutions.data.map((item: any, index: number) => {
                      const Icon = IconProvider.get(item.attributes.Icon.Name);
                      return (
                        <SolutionItem
                          key={index} 
                          title={item.attributes.Name}
                          icon={<Icon fontSize="inherit" color="primary" />}
                          description={item.attributes.ShortDescription}
                          link={item.attributes.Path}
                        />
                      );
                    })
                  }
                </div>
              </div>
            </>
            : null
        }
      </div>
    </>
  );
};

export default Home;
import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './footer.module.css';
import appScreenshot from '../../assets/app-screenshot.jpg';
import appStoreBadge from '../../assets/app-store-badge.png';
import playStoreBadge from '../../assets/play-store-badge.png';
import FadeInDiv from '../fade-in-div/fade-in-div';
import FollowUs from '../follow-us/follow-us';

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.help}>
        <FadeInDiv className={styles.helpFadein}>
          <Arrow fontSize="inherit" color="primary" />
          <div>
            <h3>How can we help you?</h3>
            <p>Get in <Link to="/contact">touch</Link></p>
          </div>
        </FadeInDiv>
      </div>
      <div className={styles.info}>
        <div className={styles.box}>
          <img src={appScreenshot} alt="Optius app screenshot" />
          <div className={`${styles.text} ${styles.data}`}>
            <div className={styles.subtitle}>
              <Arrow fontSize="inherit" color="primary" />
              <p>DATA</p>
            </div>
            <div className={styles.indent}>
              <p><b>Owner of consumer app Optius</b></p>
              <br />
              <p>
                Visit website<br />
                <a href="https://optius.app" target="_blank" rel="noreferrer">optius.app</a>
                <a href="https://www.instagram.com/optius.app" target="_blank" rel="noreferrer">Follow on Instagram</a>
                <a href="https://www.facebook.com/OptiusDK" target="_blank" rel="noreferrer">Follow on Facebook</a>
              </p>
              <br />
              <a href="https://apps.apple.com/us/app/optius/id1540805474" target="_blank" rel="noreferrer">
                <img src={appStoreBadge} alt="Download Optius app from App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=app.optius" target="_blank" rel="noreferrer">
                <img src={playStoreBadge} alt="Download Optius app from Google Play" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.box}>
          <div className={styles.text}>
            <div className={styles.subtitle}>
              <Arrow fontSize="inherit" color="primary" />
              <p>FOLLOW US</p>
            </div>
            <div className={styles.indent}>
              <FollowUs />
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.box}>
          <div className={styles.text}>
            <div className={styles.subtitle}>
              <Arrow fontSize="inherit" color="primary" />
              <p>LEGAL & ETHICS</p>
            </div>
            <div className={styles.indent}>
              <Link to="/alt/privacy-policy">Privacy policy</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copy}>
        <p>Copyright &copy; Heco et al. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
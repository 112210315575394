import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/layout/layout';
import Home from './containers/home/home';
import theme from './theme';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Solutions from './containers/solutions/solutions';
import Solution from './containers/solution/solution';
import Contact from './containers/contact/contact';
import OurTeam from './containers/our-team/our-team';
import Insights from './containers/insights/insights';
import Insight from './containers/insight/insight';
import TextPage from './containers/text-page/text-page';

const client = new ApolloClient({
  uri: process.env.REACT_APP_CMS_API_ENDPOINT,
  cache: new InMemoryCache(),
});

const App: React.FC = () => {
  const location = useLocation();
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="solutions" element={<Solutions />} />
            <Route path="solution/:path" element={<Solution />} />
            <Route path="insights" element={<Insights />} />
            <Route path="our-team" element={<OurTeam />} />
            <Route path="contact" element={<Contact />} />
          </Route>
          <Route path="/alt" element={<Layout alt={true} />}>
            <Route path="insight/:path" element={<Insight />} />
            <Route path="privacy-policy" element={<TextPage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;

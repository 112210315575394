import React from 'react';
import Arrow from '../arrow/arrow';
import styles from './loader.module.css';

interface Props {
  className?: string;
  color?: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

const Loader: React.FC<Props> = props => {
  return (
    <div className={`${styles.loader} ${props.className || ''}`}>
      <Arrow className={styles.arrow} color={props.color} />
    </div>
  );
};

export default Loader;
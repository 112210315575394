import React from 'react';
import useOnScreen from '../../hooks/use-on-screen';
import styles from './fade-in-div.module.css';

const FadeInDiv: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = props => {
  const ref = React.createRef<HTMLDivElement>();
  const isVisible = useOnScreen(ref);
  
  return (
    <div ref={ref} {...props} className={`${props.className || ''} ${styles.wrap} ${isVisible ? styles.active : ''}`} />
  );
};

export default FadeInDiv;
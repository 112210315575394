import React from 'react';
import Hero from '../../components/hero/hero';
import styles from './our-team.module.css';
import bgImage from '../../assets/our-team.jpg';
import { useQuery } from '@apollo/client';
import { getOurTeamData } from '../../queries';
import TeamMemberItem from '../../components/team-member-item/team-member-item';

const OurTeam: React.FC = () => {
  const {data} = useQuery(getOurTeamData);

  React.useEffect(() => {
    document.title = 'Heco et al - Our team';
    document.querySelector('meta[name="description"]')?.setAttribute('content', data?.ourTeam.data.attributes.Subtitle);
  }, [data]);

  return (
    <>
      <Hero img={bgImage} title={data?.ourTeam.data.attributes.Title} text={data?.ourTeam.data.attributes.Subtitle} />
      <div className={styles.ourTeam}>
        {
          data ?
            <>
              <div className={styles.teamWrap}>
                <h2>Get in touch</h2>
                <div>
                  {
                    data.coreTeams.data.map((item: any, index: number) => {
                      return (
                        <TeamMemberItem
                          key={index}
                          name={item.attributes.Name}
                          title={item.attributes.Title}
                          email={item.attributes.Email}
                          phone={item.attributes.Phone}
                          linkedIn={item.attributes.LinkedIn}
                          details={item.attributes.Details}
                          imageUrl={`${process.env.REACT_APP_CMS_ENDPOINT}${item.attributes.Image.data?.attributes.formats.small?.url || item.attributes.Image.data?.attributes.formats.thumbnail?.url}`}
                        />
                      );
                    })
                  }
                </div>
              </div>
              <div className={styles.teamWrap}>
                <h2>Advisory board</h2>
                <div>
                  {
                    data.advisoryBoardMembers.data.map((item: any, index: number) => {
                      return (
                        <TeamMemberItem
                          key={index}
                          name={item.attributes.Name}
                          title={item.attributes.Title}
                          email={item.attributes.Email}
                          phone={item.attributes.Phone}
                          linkedIn={item.attributes.LinkedIn}
                          details={item.attributes.Details}
                          imageUrl={`${process.env.REACT_APP_CMS_ENDPOINT}${item.attributes.Image.data?.attributes.formats.small?.url || item.attributes.Image.data?.attributes.formats.thumbnail?.url}`}
                        />
                      );
                    })
                  }
                </div>
              </div>
            </>
            : null
        }
      </div>
    </>
  );
};

export default OurTeam;
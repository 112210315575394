import React from 'react';
import styles from './follow-us.module.css';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LinkedIn from '../linkedin/linkedin';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import config from '../../config';
import EmailSubscribe from '../email-subscribe/email-subscribe';

const FollowUs: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.item}>
        <LocationOnOutlinedIcon fontSize="inherit" />
        <a href={config.mapUrl} target="_blank" rel="noreferrer">Nordensvej 1, 7000, Denmark</a>
      </div>
      <div className={styles.item}>
        <LinkedIn fontSize="inherit" />
        <a href={config.linkedInUrl} target="_blank" rel="noreferrer">Follow us on Linkedin</a>
      </div>
      <div className={styles.item}>
        <CampaignOutlinedIcon fontSize="inherit" />
        <p>Get insights</p>
      </div>
      <EmailSubscribe />
    </div>
  );
};

export default FollowUs;
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SavedSearchOutlinedIcon from '@mui/icons-material/SavedSearchOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

export type IconName = 'SaveOutlined' | 'PsychologyOutlined' | 'ArticleOutlined' | 'SavedSearchOutlined' | 'RocketLaunchOutlined' | 'ScienceOutlined'
                  | 'TuneOutlined' | 'DesignServicesOutlined' | 'AutoGraphOutlined' | 'CalculateOutlined' | 'CategoryOutlined';

class IconProvider {
  static get(name: IconName): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {muiName: string;} {
    if (name === 'SaveOutlined') {
      return SaveOutlinedIcon;
    }
    if (name === 'PsychologyOutlined') {
      return PsychologyOutlinedIcon;
    }
    if (name === 'ArticleOutlined') {
      return ArticleOutlinedIcon;
    }
    if (name === 'SavedSearchOutlined') {
      return SavedSearchOutlinedIcon;
    }
    if (name === 'RocketLaunchOutlined') {
      return RocketLaunchOutlinedIcon;
    }
    if (name === 'ScienceOutlined') {
      return ScienceOutlinedIcon;
    }
    if (name === 'TuneOutlined') {
      return TuneOutlinedIcon;
    }
    if (name === 'DesignServicesOutlined') {
      return DesignServicesOutlinedIcon;
    }
    if (name === 'AutoGraphOutlined') {
      return AutoGraphOutlinedIcon;
    }
    if (name === 'CalculateOutlined') {
      return CalculateOutlinedIcon;
    }
    if (name === 'CategoryOutlined') {
      return CategoryOutlinedIcon;
    }
    return CheckBoxOutlineBlankIcon;
  }
}

export default IconProvider;
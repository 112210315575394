import React, { ReactElement } from 'react';
import styles from './solution-item.module.css';
import showdown from 'showdown';
import sanitize from 'sanitize-html';
import { Link } from 'react-router-dom';
import Arrow from '../arrow/arrow';

const MarkdownConverter = new showdown.Converter();

const SolutionItem: React.FC<{title: string; icon: ReactElement; description: string; link: string}> = ({title, icon, description, link}) => {
  const decritpionHtml = MarkdownConverter.makeHtml(description);
  return (
    <Link className={styles.solutionItem} to="/our-team">
      <div className={styles.header}>
        {icon}
        <h3>{title}</h3>
      </div>
      <div className={styles.description} dangerouslySetInnerHTML={{__html: sanitize(decritpionHtml)}} />
      <div className={styles.readMore}>
        <Arrow color="primary" fontSize="inherit" />
        <p>Contact us</p>
      </div>
    </Link>
  );
};

export default SolutionItem;
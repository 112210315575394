import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useMatch } from 'react-router-dom';
import { getSolutionDetails } from '../../queries';
import IconProvider from '../../services/icon-provider';
import styles from './solution.module.css';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import sanitize from 'sanitize-html';
import showdown from 'showdown';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import Gallery from '../../components/gallery/gallery';

const MarkdownConverter = new showdown.Converter();

const ExplainerItem: React.FC<{ icon: ReactElement; title: string; subtitle: string; content: string }> = props => {
  const contentHtml = MarkdownConverter.makeHtml(props.content);

  return (
    <div className={styles.explainerItem}>
      <div className={styles.explainerItemHeader}>
        {props.icon}
        <div>
          <h2>{props.title}</h2>
          <p>{props.subtitle}</p>
        </div>
      </div>
      <div className={styles.explainerItemContent} dangerouslySetInnerHTML={{ __html: sanitize(contentHtml) }} />
    </div>
  );
};

const Solution: React.FC = () => {
  const match = useMatch('/solution/:path');
  const path = match?.params.path;
  const { loading, data } = useQuery(getSolutionDetails, { variables: { path } });
  const details = data && data.solutions.data.length > 0 ? data.solutions.data[0].attributes : null;
  const Icon = details && details.Icon.Name ? IconProvider.get(details.Icon.Name) : null;
  const [isOnTop, setIsOnTop] = React.useState<boolean>(true);

  const scrollHandler = () => {
    setIsOnTop(window.scrollY < 10);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  React.useEffect(() => {
    if (details) {
      document.title = details.FullName || details.Name || 'Heco et al - Solutions';
      document.querySelector('meta[name="description"]')?.setAttribute('content', '');
    }
  }, [details]);

  if (!path || (!loading && !details)) {
    return <Navigate to="/solutions" />;
  }

  return (
    <>
      {
        Icon && details ?
          <div className={`${styles.header} ${isOnTop ? styles.isOnTop : ''}`}>
            <Icon fontSize="inherit" />
            <h1>{details.FullName || details.Name}</h1>
          </div>
          :
          null
      }
      <div className={styles.description}>
        {
          details ?
            <>
              <div className={styles.content}>
                <ExplainerItem
                  title="Your Situation"
                  subtitle="The context of your products, brand and company could be some of the following"
                  icon={<PublicOutlinedIcon fontSize="inherit" />}
                  content={details.Situation}
                />
                <ExplainerItem
                  title="Your Challenge"
                  subtitle="The following are the challenges that we among others can overcome"
                  icon={<FitnessCenterOutlinedIcon fontSize="inherit" />}
                  content={details.Challange}
                />
                <ExplainerItem
                  title="Insights backing up your solution"
                  subtitle="Scientific insights combined with your creativity can boost your solution"
                  icon={<AutoGraphOutlinedIcon fontSize="inherit" />}
                  content={details.Insights}
                />
                <ExplainerItem
                  title="Our scientific approach"
                  subtitle="How do we approach the task?"
                  icon={<PsychologyOutlinedIcon fontSize="inherit" />}
                  content={details.Approuch}
                />
              </div>
              <div className={styles.exampleContainer}>
                <div className={styles.example}>
                  {
                    details.Images.data && details.Images.data.length > 0 ?
                      <>
                        <p><i>{details.Images.data[0].attributes.caption}</i></p>
                        <img
                          src={`${process.env.REACT_APP_CMS_ENDPOINT}${details.Images.data[0].attributes.formats.medium.url}`}
                          alt={details.Images.data[0].attributes.caption}
                        />
                      </>
                      : null
                  }
                </div>
                <div className={styles.example}>
                  {
                    details.RightSidePicture.data ?
                      <>
                        <p><i>{details.RightSidePicture.data.attributes.caption}</i></p>
                        <img
                          src={`${process.env.REACT_APP_CMS_ENDPOINT}${details.RightSidePicture.data.attributes.formats.medium.url}`}
                          alt={details.RightSidePicture.data.attributes.caption}
                        />
                      </>
                      : null
                  }
                </div>
              </div>
            </>
            : null
        }
      </div>
      {
        details && details.Images.data.length > 1 ?
          <Gallery className={styles.gallery} images={details.Images.data.reduce((acc: { urlLarge: string, urlThumb: string, caption: string }[], curr: any) => acc = [...acc, { urlLarge: curr.attributes.formats.large.url, urlThumb: curr.attributes.formats.thumbnail.url, caption: curr.attributes.caption }], [])} />
          : null
      }
    </>
  );
};

export default Solution;
import React from 'react';
import Hero from '../../components/hero/hero';
import styles from './contact.module.css';
import bgImage from '../../assets/contact.jpg';
import config from '../../config';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LinkedIn from '../../components/linkedin/linkedin';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import EmailSubscribe from '../../components/email-subscribe/email-subscribe';
import { useQuery } from '@apollo/client';
import { getContactHeader } from '../../queries';

const Contact: React.FC = () => {
  const {data} = useQuery(getContactHeader);

  React.useEffect(() => {
    document.title = 'Heco et al - Contact us';
    document.querySelector('meta[name="description"]')?.setAttribute('content', data?.contactHeader.data.attributes.Subtitle);
  }, [data]);

  return (
    <>
      <Hero img={bgImage} title={data?.contactHeader.data.attributes.Title} text={data?.contactHeader.data.attributes.Subtitle} />
      <div className={styles.content}>
        <div>
          <div className={styles.item}>
            <LocationOnOutlinedIcon fontSize="inherit" />
            <a href={config.mapUrl} target="_blank" rel="noreferrer"><address>Nordensvej 1, Frederecia 7000, Denmark</address></a>
          </div>
          <div className={styles.item}>
            <LocalPhoneOutlinedIcon fontSize="inherit" />
            <p>{config.phone}</p>
          </div>
          <div className={styles.item}>
            <EmailOutlinedIcon fontSize="inherit" />
            <a href={`mailto:${config.email}`} target="_blank" rel="noreferrer">{config.email}</a>
          </div>
          <div className={styles.item}>
            <LinkedIn fontSize="inherit" />
            <a href={config.linkedInUrl} target="_blank" rel="noreferrer">Follow us on Linkedin</a>
          </div>
          <div className={styles.item}>
            <CampaignOutlinedIcon fontSize="inherit" />
            <p>Get insights</p>
          </div>
          <EmailSubscribe />
        </div>
        <div className={styles.map} onClick={() => window.open(config.mapUrl, '_blank')} />
      </div>
    </>
  );
};

export default Contact;
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B0F0',
    },
    secondary: {
      main: '#000000',
    },
    success: {
      main: '#4BB543',
    },
  },
});

export default theme;
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './header.module.css';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '../menu/menu';
//import { useQuery } from '@apollo/client';
//import { getSolutionsPaths } from '../../queries';

const Header: React.FC<{alt?: boolean}> = props => {
  const navigate = useNavigate();
  const [isOnTop, setIsOnTop] = React.useState<boolean>(true);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const location = useLocation();
  //const {data} = useQuery(getSolutionsPaths);

  const scrollHandler = () => {
    setIsOnTop(window.scrollY < 10);
  };

  const resizeHandler = () => {
    setIsMobile(window.innerWidth < 1100);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  React.useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  React.useEffect(() => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', isOnTop ? '#ffffff' : '#000000');
  }, [isOnTop]);

  const Logo: React.FC = () => {
    return (
      <div className={styles.logo} onClick={() => navigate('/')}>
        <Arrow fontSize="inherit" color="primary" />
        <h1>Heco et al</h1>
      </div>
    );
  };

  return (
    <div className={`${styles.header} ${props.alt ? styles.altColor : ''} ${location.pathname.includes('/solution/') ? styles.altColor : ''}`} style={{padding: !isOnTop ? '0.8rem' : '1.7rem'}}>
      <Logo />
      <div className={styles.links}>
        <div className={styles.solutionsLinkWrap}>
          <Link to="/solutions">Solutions</Link>
          <div className={styles.popupMenu}>
            {/*
            <div>
              {
                data ?
                  data.solutions.data.map((item: any, index: number) => {
                    return (
                      <Link key={index} to={`/solution/${item.attributes.Path}`}>{item.attributes.Name}</Link>
                    );
                  })
                  : null
              }
            </div>
            */}
          </div>
        </div>
        <Link to="/insights">Insights</Link>
        <Link to="/our-team">Our team</Link>
        <Link to="/contact">Contact</Link>
        <div className={styles.login}>
          <Avatar className={styles.avatar}>
            <PersonIcon color="inherit" />
          </Avatar>
          <Link to="#" onClick={() => setIsMenuOpen(true)}>Log in</Link>
        </div>
      </div>

      {
        !isOnTop || isMobile ?
          <div className={`${styles.menuIcon} ${!isOnTop ? styles.alt : ''}`} onClick={() => setIsMenuOpen(true)}>
            <MenuIcon color="inherit" fontSize="inherit" />
          </div>
          : null
      }

      <div className={`${styles.altHeader} ${!isOnTop || isMobile ? styles.active : ''}`}>
        <Logo />
      </div>

      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onOpen={() => setIsMenuOpen(true)}
      />
    </div>
  );
};

export default Header;
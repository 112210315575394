import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './insights-item.module.css';

interface Props {
  img: string;
  summary: string;
  publishedAt: Date;
  title: string;
  path: string;
}

const InsightsItem: React.FC<Props> = props => {
  return (
    <Link className={styles.wrap} to={`/alt/insight/${props.path}`}>
      <div className={styles.image} style={{backgroundImage: `url(${props.img})`}} />
      <div className={styles.content}>
        <h2>{props.title}</h2>
        <p>{props.summary}</p>
        <p className={styles.date}><i>{props.publishedAt.toLocaleDateString('default', {month: 'long', day: 'numeric', year: 'numeric'})}</i></p>
      </div>
      <Arrow className={styles.arrow} color="primary" fontSize="inherit" />
    </Link>
  );
};

export default InsightsItem;
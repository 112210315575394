import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import {default as SwipeableDrawer, SwipeableDrawerProps} from '@mui/material/SwipeableDrawer';
import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../arrow/arrow';
import styles from './menu.module.css';
import PersonIcon from '@mui/icons-material/Person';

const Menu: React.FC<SwipeableDrawerProps> = props => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleLogin = () => {
    if (!email) return setError('Email is a required field.');
    if (!password) return setError('Password is a required field.');

    setError('');
    setIsLoading(true);
    setTimeout(() => {
      setError('Incorrect email or password.');
      setIsLoading(false);
    }, 500);
  };

  return (
    <SwipeableDrawer anchor="right" {...props}>
      <Box className={styles.container} role="presentation">
        <List>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <Link to="/solutions" onClick={e => props.onClose(e)}>Solutions</Link>
          </ListItem>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <Link to="/insights" onClick={e => props.onClose(e)}>Insights</Link>
          </ListItem>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <Link to="/our-team" onClick={e => props.onClose(e)}>Our team</Link>
          </ListItem>
          <ListItem>
            <Arrow color="primary" fontSize="inherit" />
            <Link to="/contact" onClick={e => props.onClose(e)}>Contact</Link>
          </ListItem>
        </List>
        <div className={styles.loginWrap}>
          <div className={styles.login}>
            <Avatar className={styles.avatar}>
              <PersonIcon color="inherit" />
            </Avatar>
            <p>Log in</p>
          </div>
          <TextField className={styles.input} variant="outlined" value={email} type="email" label="Email" onChange={e => setEmail(e.target.value)} fullWidth />
          <TextField className={styles.input} variant="outlined" value={password} type="password" label="Password" onChange={e => setPassword(e.target.value)} fullWidth />
          <Button className={styles.button} fullWidth variant="contained" color="secondary" startIcon={<Arrow color="primary" />} onClick={handleLogin}>Log in</Button>
          <Link className={styles.contact} to="/contact" onClick={e => props.onClose(e)}>Don't have an account? Get in touch!</Link>
          {error ? <Alert severity="error" className={styles.alert}>{error}</Alert> : null}

          <Backdrop open={isLoading}>
            <CircularProgress className={styles.loader} color="inherit" />
          </Backdrop>
        </div>
      </Box>
    </SwipeableDrawer>
  );
};

export default Menu;
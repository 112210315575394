import React from 'react';
import { Link } from 'react-router-dom';
import FadeInDiv from '../fade-in-div/fade-in-div';
import styles from './hero.module.css';

interface Props {
  img: string;
  title?: string;
  text?: string;
  className?: string;
  button?: string;
}

const Hero: React.FC<Props> = props => {
  return (
    <div className={`${styles.banner} ${props.className || ''}`} style={{ backgroundImage: `url(${props.img})` }}>
      {
        props.title || props.text || props.button ?
          <FadeInDiv className={styles.bannerText}>
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            {props.button ?
              <Link to={'/solution/trust-tracker'}>
                <button >{props.button}</button>
              </Link>
              :
              null
            }
          </FadeInDiv>
          : null
      }
    </div >
  );
};

export default Hero;
import { gql } from '@apollo/client';

export const getSolutionsPaths = gql`
  query {
    solutions(sort: "Order") {
      data {
        attributes {
          Name
          Order
          Path
        }
      }
    }
  }
`;

export const getHomeData = gql`
  query {
    solutionsSection {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
    solutions(sort: "Order") {
      data {
        attributes {
          Name
          Path
          Icon {Name}
          ShortDescription
        }
      }
    }
    featuredQualities(sort: "Order") {
      data {
        attributes {
          Name
          Description
        }
      }
    }
  }
`;

export const getSolutionsPageData = gql`
  query {
    solutionsSection {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
    solutions(sort: "Order") {
      data {
        attributes {
          Name
          Path
          Icon {Name}
          ShortDescription
        }
      }
    }
  }
`;

export const getSolutionDetails = gql`
  query Solutions($path: String!) {
    solutions (filters: {Path: {eq: $path}}) {
      data {
        attributes {
          Name
          FullName
          Path
          Icon {Name}
          ShortDescription
          Insights
          Situation
          Situation
          Approuch
          Challange
          Images {
            data {
              attributes {
                caption
                formats
              }
            }
          }
          RightSidePicture {
            data {
              attributes {
                caption
                formats
              }
            }
          }
        }
      }
    }
  }
`;

export const getOurTeamData = gql`
  query {
    ourTeam {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
    coreTeams(sort: "Order") {
      data {
        attributes {
          Name
          Title
          Phone
          Email
          LinkedIn
          Details
          Image {
            data {
              attributes {
                formats
              }
            }
          }
        }
      }
    }
    advisoryBoardMembers(sort: "Order") {
      data {
        attributes {
          Name
          Title
          Details
          Image {
            data {
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;

export const getInsights = gql`
  query Insights($page: Int!, $pageSize: Int!) {
    insightsHeader {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
    insights(sort: "publishedAt:DESC", pagination: {page:$page, pageSize: $pageSize}) {
      data {
        attributes {
          Title
          Path
          Summary
          publishedAt
          Image {
            data {
              attributes {
                formats
              }
            }
          }
        }
      }
      meta {
        pagination {
          pageCount
        }
      }
    }
  }
`;

export const getInsight = gql`
  query Insights($path: String!) {
    insights (filters: {Path: {eq: $path}}) {
      data {
        attributes {
          Title
          Path
          Summary
          Content
          publishedAt
          ImageDisplayType
          Image {
            data {
              attributes {
                caption
                formats
              }
            }
          }
        }
      }
    }
  }
`;

export const getInsightsNewsletterHeadline = gql`
  query {
    insightsNewsletterHeadline {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
  }
`;

export const getPrivacyPolicy = gql`
  query {
    privacyPolicy {
      data {
        attributes {
          Title
          Content
        }
      }
    }
  }
`;

export const getContactHeader = gql`
  query {
    contactHeader {
      data {
        attributes {
          Title
          Subtitle
        }
      }
    }
  }
`;
import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { getPrivacyPolicy } from '../../queries';
import styles from './text-page.module.css';
import showdown from 'showdown';

const MarkdownConverter = new showdown.Converter();



const TextPage: React.FC = () => {
  const location = useLocation();
  const query = location.pathname === '/alt/privacy-policy' ? getPrivacyPolicy : null;
  
  if (!query) {
    throw new Error('Path not implemented.');
  }

  const {data} = useQuery(query);
  const details = data ? data.privacyPolicy.data.attributes : null;
  const [isOnTop, setIsOnTop] = React.useState<boolean>(true);

  const scrollHandler = () => {
    setIsOnTop(window.scrollY < 10);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  React.useEffect(() => {
    document.title = details?.Title || 'Heco et al';
    document.querySelector('meta[name="description"]')?.setAttribute('content', '');
  }, [details]);

  return (
    <>
      {
        details ? 
          <>
            <div className={`${styles.header} ${isOnTop ? styles.isOnTop : ''}`}>
              <h1>{details.Title}</h1>
            </div>
            <div className={styles.description}>
              <div className={styles.content} dangerouslySetInnerHTML={{__html: MarkdownConverter.makeHtml(details.Content)}} />
            </div>
          </>
          :
          null
      }
    </>
  );
};

export default TextPage;
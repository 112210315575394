import React from 'react';
import styles from './newsletters-subscribe.module.css';
import EmailSubscribe from '../../components/email-subscribe/email-subscribe';
import { getInsightsNewsletterHeadline } from '../../queries';
import { useQuery } from '@apollo/client';

interface Props {
  className?: string;
}

const NewslettersSubscribe: React.FC<Props> = props => {
  const {data} = useQuery(getInsightsNewsletterHeadline);

  if (!data) return null;

  return (
    <div className={`${styles.headline} ${props.className || ''}`}>
      <h3>{data.insightsNewsletterHeadline.data.attributes.Title}</h3>
      <p>{data.insightsNewsletterHeadline.data.attributes.Subtitle}</p>
      <EmailSubscribe className={styles.emailInput} buttonTextOverride="Subscribe" />
    </div>
  );
};

export default NewslettersSubscribe;
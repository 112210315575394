import React from 'react';

const useOnScreen = (element: React.RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      setIntersecting(true);
      observer.disconnect();
    }
  });

  React.useEffect(() => {
    if (element.current) {
      observer.observe(element.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [!element.current]);

  return isIntersecting;
};

export default useOnScreen;
import React from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useMatch } from 'react-router-dom';
import { getInsight } from '../../queries';
import styles from './insight.module.css';
import showdown from 'showdown';
import sanitize from 'sanitize-html';
import NewslettersSubscribe from '../../components/newsletters-subscribe/newsletters-subscribe';

const MarkdownConverter = new showdown.Converter();

const Insight: React.FC = () => {
  const match = useMatch('/alt/insight/:path');
  const path = match?.params.path;
  const {loading, data} = useQuery(getInsight, {variables: {path}});
  const [contentHtml, setContentHtml] = React.useState<string>('');
  const details = data && data.insights.data.length > 0 ? data.insights.data[0].attributes : null;
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (details) {
      setContentHtml(MarkdownConverter.makeHtml(details.Content));
    }
  }, [!details]);

  React.useEffect(() => {
    document.title = details?.Title || 'Heco et al - Insights';
    document.querySelector('meta[name="description"]')?.setAttribute('content', details?.Summary);
    document.querySelector('meta[name="title"]')?.setAttribute('content', details?.Title || 'Heco et al - Insights');
    document.querySelector('meta[name="image"]')?.setAttribute('content', `${process.env.REACT_APP_CMS_ENDPOINT}${details?.Image.data.attributes.formats.medium.url}`);
    document.querySelector('meta[property="og:type"]')?.setAttribute('content', 'article');
  }, [details]);

  React.useEffect(() => {
    if (contentRef.current) {
      const images = contentRef.current.getElementsByTagName('img');
      for (let i = 0; i < images.length; i++) {
        const img = images.item(i);
        if (img?.src) {
          const name = img?.src?.split('/uploads/')[1];
          img.src = `${process.env.REACT_APP_CMS_ENDPOINT}/uploads/medium_${name}`;
        }
      }
    }
  }, [!contentRef.current, contentHtml]);

  if (!path || (!loading && !details)) {
    return <Navigate to="/insights" />;
  }

  return (
    <>
      <div className={styles.insight}>
        {
          !loading && details && contentHtml ?
            <>
              <h1>{details.Title}</h1>
              <p className={styles.summary}>{details.Summary}</p>
              <p className={styles.date}><i>{new Date(details.publishedAt).toLocaleDateString('default', {month: 'long', day: 'numeric', year: 'numeric'})}</i></p>
              <div
                className={styles.featuredImage}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_CMS_ENDPOINT}${details.Image.data.attributes.formats.medium.url})`,
                  backgroundSize: details.ImageDisplayType === 'contain' ? 'contain' : 'cover',
                }}
              />
              <p className={styles.featuredImageCaption}>{details.Image.data.attributes.caption}</p>
              <div
                ref={contentRef}
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: sanitize(contentHtml, {
                    allowedAttributes: {'img': ['src', 'alt']},
                    allowedTags: sanitize.defaults.allowedTags.concat(['img', 'imagecaption']),
                  }),
                }}
              />
            </>
            : null
        }
      </div>
      <div className={styles.divider} />
      <NewslettersSubscribe className={styles.subscribe} />
      <br /><br /><br />
    </>
  );
};

export default Insight;